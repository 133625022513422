<template lang="pug">
  EditDialog(ref="Self" title="配車パターン乗務員選択"  width="80%" content-class="edit-dialog-80"
    :hasTitleSuffix="false" @closeEditDialog="clearData" :enableSave="false"
    :loadingSave="loading.save" :loadingDelete="loading.delete" :scroll-fire="onScrollButton"
    :enable-scroll-fire="scrollFireFlg")
    template(v-slot:body)
      v-container(grid-list-md fluid)
        LoadingWrapper(:isDisplay="loading" :positionFixed="false")
        v-layout(row wrap)
          v-flex(xs12)
            DispatchPatternMatchDriverFilterConditions(@onChangeSearchConditions="onChangeSearchConditions" ref="cond")
            DispatchPatternSaveConfirmDialog(ref="confirmDialog" @yes="onSave")
            EmergencyErrorDialog(ref="errorMsg")
        v-layout(row wrap)
          v-flex(xs12)
            v-card
              v-card-text
                DispatchPatternTable(ref="ListTable" :prop-header="tableHeader" :prop-data="matchedList" displaySlotBody
                :getListActionName="getListActionName" @triggerSelect="onDriverSelect")
                  template(v-slot:body="{body}")
                    td
                      span ({{body.primaryDeliveryCompanyCd}}) {{body.primaryDeliveryCompanyName}}
                    td
                      span ({{body.deliveryCompanyCd}}) {{body.deliveryCompanyName}}
                    td
                      span {{body.driverName}}
                    td
                      span {{body.vehicle.vehicleNumber}}
                    td.text-xs-center
                      //span {{getShiftTypeLabel(body.shiftType)}}
                      v-icon(:color="getShiftType(body.shiftType).color") {{ getShiftType(body.shiftType).icon }}
                    td
                      span {{body.vehicle.garageArea}}
                    td
                      TipsOneLineText(:text="body.driverRemark")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog.vue";
import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import DispatchPatternTable from "@/components/common/PagingListTable";

import { mapActions, mapGetters } from "vuex";
import DispatchPatternMatchDriverFilterConditions from "@/components/dispatch-pattern/DispatchPatternMatchDriverFilterConditions.vue";
import EnumLabelUtils from "@/mixin/EnumLabelUtils";
import DispatchPatternSaveConfirmDialog from "@/components/dispatch-pattern/DispatchPatternSaveConfirmDialog.vue";
import EventDispatcher from "@/mixin/EventDispatcher";
import EmergencyErrorDialog from "@/components/common/EmergencyErrorDialog";
import TipsOneLineText from "@/components/common/TipsOneLineText";
import ShiftTypeMenu from "@/mixin/ShiftTypeMenu";
import LoadingWrapper from "@/components/common/LoadingWrapper.vue";

export default {
  components: {
    LoadingWrapper,
    DispatchPatternSaveConfirmDialog,
    DispatchPatternMatchDriverFilterConditions,
    EditDialog,
    DispatchPatternTable,
    EmergencyErrorDialog,
    TipsOneLineText
  },
  mixins: [ApiHandler, EditForm, EnumLabelUtils, EventDispatcher, ShiftTypeMenu],
  computed: {
    ...mapGetters({
      matchedList: "dispatchPattern/getMatchedDriverList",
      getActiveDriversSchedule: "dispatch/getActiveDriversSchedule"
    }),
    getListActionName: {
      get() {
        return "dispatchPattern/fetchAllPatternMatchedDriverList";
      }
    },
    tableHeader: {
      get() {
        return [
          { text: "1次会社", value: "", centerAlign: false },
          { text: "実会社", value: "", centerAlign: false },
          { text: "乗務員名", value: "", centerAlign: false, width: "150px" },
          { text: "車番", value: "", centerAlign: false, width: "120px" },
          { text: "シフト", value: "", centerAlign: false, width: "100px" },
          { text: "車庫エリア", value: "", centerAlign: false, width: "150px" },
          { text: "備考", value: "", centerAlign: false, width: "150px" }
        ];
      }
    },
    scrollFireFlg: {
      get() {
        return true;
      }
    }
  },
  data() {
    return {
      searchCond: {},
      selectDriverId: null,
      loading: false
    };
  },
  methods: {
    ...mapActions({
      setSelectedDriverInfo: "dispatchPattern/setSelectedDriverInfo",
      postDispatchPatternDeploy: "dispatchPattern/postDispatchPatternDeploy",
      getScheduleListSpecifiedDriver: "dispatch/getScheduleListSpecifiedDriver",
      setSelectedDispatchDriverInfo: "dispatchPattern/setSelectedDispatchDriverInfo",
      removeDispatchPatternDriverData: "dispatchPattern/removeDispatchPatternDriverData"
    }),
    openDialog() {
      this.$refs.cond.submit();
      this.$refs.Self.openDialog();
    },
    closeDialog() {
      this.clearData();
      this.$refs.Self.closeDialog();
    },
    clearData() {
      this.removeDispatchPatternDriverData();
    },
    loadListData() {
      const self = this;
      this.loading = true;
      return this.$refs.ListTable.loadData().finally(() => {
        self.loading = false;
      });
    },
    onScrollButton() {
      // this.setDispatchPatternCurrentPage(this.dispatchPatternCurrentPage * 1 + 1);
      // this.loadListData();
      // return Promise.resolve();
      this.$refs.ListTable.addPageCount();
      return this.loadListData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = Object.assign({}, this.searchCond, searchCond);
      this.$refs.ListTable.setSearchConditions(this.searchCond);
      this.$refs.ListTable.resetPageCount();
      this.loadListData();
    },
    onDriverSelect(item) {
      const self = this;
      this.selectDriverId = item.driverId;
      //選択された乗務員の情報をStore保存し､
      //該当乗務員の現歯医者情報を確認ダイアログに表示する
      this.setSelectedDriverInfo(item)
        .then(() => {
          self.loadSelectDispatchDriverPreviewData();
          self.removeSubscribe();
          self.registerSubscribe();
          self.$refs.confirmDialog.openDialog();
          //選択した乗務員データリロードする必要がある際にコールする
        })
        .finally(() => {
          this.selectDriverId = null;
        });
    },
    async loadSelectDispatchDriverPreviewData() {
      this.getScheduleListSpecifiedDriver([this.selectDriverId]).then(data => {
        return this.setSelectedDispatchDriverInfo(data);
      });
    },
    /**
     * 確認ダイアログでOK押下された時
     * 保存処理（パターンに従っての配車用API）をたたく
     */
    onSave() {
      const self = this;
      self.loading = true;
      this.postDispatchPatternDeploy()
        .then(() => {
          //完了後
          self.closeDialog();
          this.dispatchCommonEvent(window.Common.Config.observer.onRequireDispatchReload);
          this.dispatchCommonEvent(window.Common.Config.observer.onRequireDispatchPatternSelectReload);
        })
        .catch(reason => {
          self.$refs.errorMsg.open(reason.message);
          self.closeDialog();
          this.dispatchCommonEvent(window.Common.Config.observer.onRequireDispatchReload);
          this.dispatchCommonEvent(window.Common.Config.observer.onRequireDispatchPatternSelectReload);
        })
        .finally(() => {
          self.loading = false;
        });
    },
    removeSubscribe() {
      window.removeEventListener(
        window.Common.Config.observer.onDispatchPatternSelectDriverReload,
        this.loadSelectDispatchDriverPreviewData
      );
    },
    registerSubscribe() {
      window.addEventListener(
        window.Common.Config.observer.onDispatchPatternSelectDriverReload,
        this.loadSelectDispatchDriverPreviewData,
        false
      );
    }
  },
  provide() {
    return {
      removeSubscribeInject: this.removeSubscribe,
      registerSubscribeInject: this.registerSubscribe
    };
  }
};
</script>

<style scoped lang="stylus">
>>> table.sticky_table {
  width: 100%;
  table-layout: fixed;

  thead {
    th {
      background-color: #FFFFFF;
      position: sticky;
      top: -15px
    }
  }
}

>>> .edit-dialog {
  max-height: 60%
}
</style>
