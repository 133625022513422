<template lang="pug">
  v-content#dispatch-schedule
    EmergencyErrorDialog(ref="errorMsg")
    WildCardEditDialog(ref="WildCardEditDialog" :postUrl="$config.urls.postEditDispatchTempCard" @saved="reloadDriverSchedule")
    DispatchRemarkEditDialog(ref="DispatchRemarkEditDialog" :postUrl="$config.urls.postSaveDispatchRemark"
      :scheduledLoadingDate="getScheduledLoadingDate" :getRemarkAction="'dispatch/fetchScheduleRemark'" @updateRemark="onSaveRemark")
    ClassifiedCardRemarkEditDialog(ref="ClassifiedCardRemarkEditDialog" :postUrl="$config.urls.postEditCardRemark"
      :getRemarkAction="'dispatch/fetchCardRemark'")
    ClassifiedCardContextMenu(ref="ClassifiedCardContextMenu"
      @openVehicleSelectDialog="openCardVehicleSelectDialog" @openSlideEditDialog="openSlideEditDialog"
      @openSlideReleaseDialog="openSlideReleaseDialog" @openCardDetailDialog="openCardDetailDialog"
      @cancelRelay="cancelRelayPre" @setNightLoading="setNightLoadingPre"
      @unSetNightLoading="unSetNightLoadingPre" @openWildCardEditDialog="openWildCardEditDialog")
    DriverContextMenu(ref="DriverContextMenu" @openDriverDetailDialog="openDriverDetailDialog"
      @openChangeVehicleDialog="openVehicleSelectDialog")
    ClassifiedCardMarkMenu(ref="ClassifiedCardMarkMenu" @error="openErrorMsgBox")
    ScheduleRelayMenu(ref="ScheduleRelayMenu" @saved="savedRelay" @openErrorMsgBox="openErrorMsgBox" @hideRelay="hideRelay")
    VehicleSelectDialog(:enableCondition="false" :label.sync="vehicleSelect.label" :code.sync="vehicleSelect.code" :errorHandlerMethod="vehicleSelect.errorHandlerMethod" :errorHandlerMethodArg="vehicleSelect.errorHandlerMethodArg" ref="VehicleSelectDialog" @changeItem="selectVehicleSelectDialog")
    SlideEditDialog(ref="SlideEditDialog" :postUrl="$config.urls.postDispatchSlide" @openErrorMsgBox="openErrorMsgBox" @saved="savedSlide")
    SlideReleaseDialog(ref="SlideReleaseDialog" :postUrl="$config.urls.postDispatchSlideRelease" @saved="releasedSlide")
    CardDetailDialog(ref="CardDetailDialog" @saved="savedCardDetailDialog" :postUrl="$config.urls.postSaveCardEdit")
    DriverDetailDialog(ref="DriverDetailDialog")
    DispatchTempCardConfirmDialog(ref="DispatchTempCardConfirmDialog" @error="openErrorMsgBox")
    DispatchSlideReleaseConfirmDialog(ref="DispatchSlideReleaseConfirmDialog" @error="openErrorMsgBox" @yes="releasedSlide")
    MailDialog(ref="MailDialog" :loadingDate="dispatchConditions.scheduledLoadingDate" @send="reload")
    NightLoadingSelectDialog(ref="NightLoadingSelectDialog" :postUrl="$config.urls.postSetNightLoadingCommit" @saved="selectNightLoading")
    ClassifiedCardPopUp(ref="ClassifiedCardPopUp")
    DispatchLineEntry(ref="DispatchLineEntry"
      :postUrl="$config.urls.postEditDriverScheduleForDispatch" :afterSavedCallBack="reloadSchedule")
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialogDriver" @yes="driverDrop")
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialogExceed" @yes="driverDayExceedDrop")
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialogClassified" @yes="classifyWrapDrop")
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialogNightLoading" @yes="setNightLoading")
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialogNightLoadingUnSet" @yes="unSetNightLoading")
    DeployWarningDialog(ref="DeployWarningDialog" @yes="driverDrop")
    DeployWarningDialog(ref="DeployWarningDialogExceed" @yes="driverDayExceedDrop")
    DeleteConfirmDialog(ref="DeleteConfirmDialog" @yes="cancelRelay"
      :message="message.relayHasResultDeleteConfirm" title="中継解除確認" buttonTitle="実績を削除して中継を解除する")
    DispatchPatternSelectDialog(ref="DispatchPatternSelectDialog")


    v-container(fluid grid-list-md fill-height)
      v-layout(row wrap ref="WrapLayout")
        v-flex.schedule-flex-layout(ref="ScheduleLayout" v-bind:style="{ 'flex-basis': layout.ScheduleLayoutWidth }")
          v-card(height="100%")
            section.schedule-wrapper
              LoadingWrapper(:isDisplay="isDisplayScheduleProgress" :positionFixed="false")
              //日付過去日警告
              section.dispatch-past-warning(v-if="isDisplayDispatchPastWarning")
                v-alert(value="true" type="error" dismissible outline)
                  span {{ this.$config.messages.Common.Warn.DispatchInPast }}
              //配車表ヘッダー（検索条件等）
              section.schedule-header-section
                //配車日と検索条件
                v-layout.schedule-filter-header(row wrap align-center justify-start)
                  v-flex(xs6)
                    v-layout(fill-height align-center justify-start)
                      div.d-inline-block
                        v-icon(v-on:click="changeScheduledLoadingDate(-1)") arrow_back_ios
                      DatePicker(:initDate="dispatchConditions.scheduledLoadingDate" :inputDate.sync="dispatchConditions.scheduledLoadingDate" :pickerLabel="labelName.VehicleDispatch" :dispWeek="true")
                      div.d-inline-block
                        v-icon(v-on:click="changeScheduledLoadingDate(1)") arrow_forward_ios
                  v-flex(xs6)
                    v-layout(fill-height align-center justify-end)
                      v-btn(@click="openDispatchDriverSelectDialog" color="success" v-if="canUseDispatchLineFeature()")
                        v-icon(left) add
                        span 配車ラインを追加

                      ReloadButton(@callReload="clickReloadButtonForSchedule")
                      v-btn(flat icon color="red")
                        v-icon(@click="clearScheduleConditions" ripple light) clear

                      v-menu(v-model="scheduleFilterMenu" :close-on-content-click="false" left)
                        v-btn(flat icon slot="activator" @click="clickScheduleConditions")
                          v-icon(v-if="isScheduleFilter" color="blue") filter_list
                          v-icon(v-else) filter_list
                        ScheduleFilterBox(ref="ScheduleFilterBox" :clientList="generalClientList" :areaList="garageAreaList"
                          @onChangeScheduleConditions="onChangeScheduleConditions")

                      v-menu(bottom left)
                        v-btn(icon flat color="grey" small slot="activator" @click="openMailDialog")
                          v-icon() send

                      v-btn(v-show="!isDisplayRelay" @click="isDisplayRelay=true" color="info")
                        v-icon(left) merge_type
                        span 中継エリアを表示
                      v-btn(v-show="isDisplayRelay" @click="isDisplayRelay=false" color="info")
                        v-icon(left) merge_type
                        span 中継エリアを非表示


                //配車表テーブルヘッダー
                v-layout.schedule-header(row)
                  div.driver-box-header
                    span 乗務員情報 {{ driverTotalCount }}

                  div.classified-order-card-row
                    div.classified-order-card-normal-area
                      div.driver-schedule-header(v-for="n in driverScheduleListMaxCount")
                        p {{ n }}
                    div.classified-order-card-day-exceed-area
                      div.driver-schedule-header
                        p (日跨ぎ用)
              //配車表テーブルボディ
              section.schedule-body-section(ref="ScheduleArea")
                //中継ライン
                v-layout#relay-row.driver-schedule-row(v-show="scheduleList.length && isDisplayRelay" row align-start justify-start)
                  v-card(elevation=0).driver-box
                    v-layout(justify-start column fill-height)
                      v-flex(xs12)
                        v-layout(align-center justify-start row fill-height)
                          v-flex.text-xs-center(xs2)
                          v-flex.text-truncate
                            span 中継エリア
                      v-flex(xs12)
                        v-layout(align-center justify-start row fill-height)
                      v-flex(xs12)
                        v-layout(align-center justify-start row fill-height)
                          v-flex
                          v-flex
                            v-btn(v-show="relayList.length > 1" color="primary" @click="openRelayMenu") 中継作成

                  div.classified-order-card-row
                    div.classified-order-card-normal-area
                      div.classified-order-card-normal-box(v-for="card in relayList" :key="card.uuid")
                        DraggableBox.drag-between-box(:item="card" :class="" boxCss='',
                          @dragStart="betweenDragStart",
                          @dragEnter="betweenDragEnterRelay",
                          @dragLeave="betweenDragLeave",
                          @drop="betweenDragDropRelay")
                          template(slot-scope="{ item }" slot="item")
                            div.content-box
                              v-icon(flat) arrow_forward_ios
                        DraggableBox.driver-schedule-box(:item="card",
                          @dragStart="driverDragStartRelay",
                          @dragEnd="driverDragEndRelay",
                          @dragEnter="driverDragEnterRelay",
                          @dragLeave="driverDragLeave",
                          @drop="driverDropRelay")
                          template(slot-scope="{ item }" slot="item")
                            ClassifiedCard.content-box(:driverId="null", :item="item", :isRelayRow="true" ,
                              @openRemarkEditDialog="openRemarkEditDialog" @openCardContextMenu="openCardContextMenu" ,
                              @openMarkMenu="openMarkMenu"
                              @triggerOpenPopUpCard="triggerOpenPopUpCard"
                              @triggerClosePopUpCard="triggerClosePopUpCard")
                //乗務員ライン
                v-layout.driver-schedule-row(row align-start justify-start v-for="(schedule, scheduleIndex) in scheduleList" :key="schedule.driverId")
                  DriverBox.driver-box(:driver="schedule" :driverIndex="scheduleIndex"
                    @openDriverContextMenu="openDriverContextMenu"
                    @openRemarkEditDialog="openDriverRemarkEditDialog"
                    @openDriverDetail="openDriverDetailDialog"
                    @toggleFavorite="toggleFavorite")

                  div.classified-order-card-row
                    div.classified-order-card-normal-area
                      div.classified-order-card-normal-box
                        DraggableBox.drag-between-box.between(:itemKey="scheduleIndex" :item="schedule.firstCard"
                          :class="['scheduleIndex_0', 'driverIndex' + scheduleIndex]" boxCss='', :driverId="schedule.driverId"
                          @dragStart="betweenDragStart",
                          @dragEnter="betweenDragEnter",
                          @dragLeave="betweenDragLeave",
                          @drop="betweenDragDropPre")
                          template(slot-scope="{ item }" slot="item")
                            div.content-box
                              DispatchFlowIcon(:item="item")
                        DraggableBox.driver-schedule-box(:itemKey="scheduleIndex" :item="schedule.firstCard" :driverId="schedule.driverId" ,
                          :class="{'different-loading-date': schedule.firstCard.isDifferentLoadingDate}",
                          @dragStart="driverDragStart",
                          @dragEnd="driverDragEnd",
                          @dragEnter="driverDragEnter",
                          @dragLeave="driverDragLeave",
                          @drop="driverDropPre")
                          template(slot-scope="{ item }" slot="item")
                            ClassifiedCard.content-box(:driverId="schedule.driverId" :itemKey="scheduleIndex" :item="item" ,
                              :displayWarning="true"
                              @openRemarkEditDialog="openRemarkEditDialog" @openCardContextMenu="openCardContextMenu" ,
                              @openMarkMenu="openMarkMenu"
                              @triggerOpenPopUpCard="triggerOpenPopUpCard"
                              @triggerClosePopUpCard="triggerClosePopUpCard")

                      div.classified-order-card-normal-box(v-for="(card , index) in schedule.cardList" :key="card.uuid")
                        DraggableBox.drag-between-box.between(:itemKey="scheduleIndex" :item="card"
                          :class="['scheduleIndex_' + index, 'driverIndex' + scheduleIndex]" boxCss='' :driverId="schedule.driverId",
                          @dragStart="betweenDragStart",
                          @dragEnter="betweenDragEnter",
                          @dragLeave="betweenDragLeave",
                          @drop="betweenDragDropPre")
                          template(slot-scope="{ item }" slot="item")
                            div.content-box
                              DispatchFlowIcon(:item="item")
                        DraggableBox.driver-schedule-box(:itemKey="scheduleIndex" :item="card" :driverId="schedule.driverId",
                          :class="{'different-loading-date': card.isDifferentLoadingDate}",
                          @dragStart="driverDragStart",
                          @dragEnd="driverDragEnd",
                          @dragEnter="driverDragEnter",
                          @dragLeave="driverDragLeave",
                          @drop="driverDropPre")
                          template(slot-scope="{ item }" slot="item")
                            ClassifiedCard.content-box(:driverId="schedule.driverId" :itemKey="scheduleIndex" :item="item" ,
                              :displayWarning="true"
                              @openRemarkEditDialog="openRemarkEditDialog" @openCardContextMenu="openCardContextMenu" ,
                              @openMarkMenu="openMarkMenu"
                              @triggerOpenPopUpCard="triggerOpenPopUpCard"
                              @triggerClosePopUpCard="triggerClosePopUpCard")

                    div.classified-order-card-day-exceed-area
                      section.drag-between-box
                        div.content-box
                          DispatchFlowIcon(:item="schedule.dayExceedCard")
                      DraggableBox.driver-schedule-box(:itemKey="scheduleIndex" :item="schedule.dayExceedCard" :driverId="schedule.driverId",
                        :class="{'different-loading-date': schedule.dayExceedCard.isDifferentLoadingDate}",
                        @dragStart="driverDragStart",
                        @dragEnd="driverDragEnd",
                        @dragEnter="driverDragEnter",
                        @dragLeave="driverDragLeave",
                        @drop="driverDayExceedDropPre")
                        template(slot-scope="{ item }" slot="item")
                          ClassifiedCard.content-box(:driverId="schedule.driverId" :itemKey="scheduleIndex" :item="item" ,
                            :displayWarning="true"
                            @openRemarkEditDialog="openRemarkEditDialog" @openCardContextMenu="openCardContextMenu" ,
                            @openMarkMenu="openMarkMenu"
                            @triggerOpenPopUpCard="triggerOpenPopUpCard"
                            @triggerClosePopUpCard="triggerClosePopUpCard")

                v-btn(fab dark absolute bottom right :color="$store.getters['site/siteColor']" :class="{ 'btn-hidden': pageTopBtnStatus.Schedule.isHidden }" @click="$vuetify.goTo(0, { container: '.schedule-body-section' })")
                  v-icon(dark) keyboard_arrow_up

        v-flex.classified-flex-layout(ref="ClassifiedLayout" v-bind:style="{ 'flex-basis': layout.ClassifiedLayoutWidth }")
          v-card(height="100%")
            section.resizer(@mousedown="initResize")
            v-tabs.classified-wrapper(color="primary darken-2" dark)
              v-tab 区分け({{ getClassifiedCardCount }})
              v-tab スライド({{ getSlideClassifiedCardCount }})
              v-tab 最近戻したカード({{ getHistoryClassifiedCardCount }})
              //区分け
              v-tab-item
                LoadingWrapper(:isDisplay="isDisplayReserveProgress" :positionFixed="false")
                v-card
                  section.classified-header-section
                    v-layout.classified-header(wrap)
                      v-flex(xs6 md-8)
                        v-layout(fill-height align-center)
                          div.d-inline-block
                            v-icon(v-on:click="changeLoadingDate(-1)") arrow_back_ios
                          DatePicker(:initDate="classifiedConditions.loadingDate" :inputDate.sync="classifiedConditions.loadingDate" :pickerLabel="labelName.LoadingSchedule")
                          div.d-inline-block
                            v-icon(v-on:click="changeLoadingDate(1)") arrow_forward_ios
                      v-flex(xs6 md-4)
                        v-layout(fill-height align-center justify-center).high-density-margin
                          v-btn(flat icon color="info")
                            v-icon(@click="openDispatchPatternDialog" ripple) pin

                          ReloadButton(@callReload="clickReloadButtonForClassify")
                          v-btn(flat icon color="red")
                            v-icon(@click="clearClassifiedConditions" ripple) clear

                          v-menu(v-model="classifiedFilterMenu" :close-on-content-click="false" width="50%" left)
                            v-btn(flat icon slot="activator" @click="filterReloadBaseAreaData")
                              v-icon(v-if="isClassifiedFilter" color="blue") filter_list
                              v-icon(v-else) filter_list
                            ClassifiedFilterCond(ref="ClassifiedFilterCond" @onChangeClassifiedConditions="onChangeClassifiedConditions")
                  section#classified-area.classified-body-section(ref="ClassifiedArea")
                    DraggableBox.draggable-item-wrap(:item="{}" boxCss='' fill-height,
                      @dragStart="classifyWrapDragStart",
                      @dragEnter="classifyWrapDragEnter",
                      @dragLeave="classifyWrapDragLeave",
                      @drop="classifyWrapDropPre")
                      template(slot-scope="{ item }" slot="item")
                        DraggableBox.classified-order-box(:item="card", v-for="card in reserveList" :key="card.uuid",
                          @dragStart="classifyDragStart",
                          @dragEnd="classifyDragEnd",
                          @dragEnter="classifyDragEnter",
                          @dragLeave="classifyDragLeave",
                          @drop="classifyDrop")
                          template(slot-scope="{ item }" slot="item")
                            ClassifiedCard.content-box(:item="item" ,
                              @openRemarkEditDialog="openRemarkEditDialog" @openCardContextMenu="openCardContextMenu" ,
                              @openMarkMenu="openMarkMenu"
                              @triggerOpenPopUpCard="triggerOpenPopUpCard"
                              @triggerClosePopUpCard="triggerClosePopUpCard")
                    v-btn(fab dark absolute bottom right :color="$store.getters['site/siteColor']" :class="{ 'btn-hidden': pageTopBtnStatus.Classified.isHidden }" @click="$vuetify.goTo(0, { container: '#classified-area' })")
                      v-icon(dark) keyboard_arrow_up
              //スライド
              v-tab-item
                LoadingWrapper(:isDisplay="isDisplaySlideProgress" :positionFixed="false")
                v-card
                  section#slide-area.classified-body-section(ref="SlideArea")
                    template(v-for="card in slideList")
                      DraggableBox.classified-order-box(:item="card" :key="card.uuid",
                        @dragStart="slideClassifyDragStart",
                        @dragEnd="slideClassifyDragEnd",
                        @dragEnter="slideClassifyDragEnter",
                        @dragLeave="slideClassifyDragLeave",
                        @drop="slideClassifyDrop")
                        template(slot-scope="{ item }" slot="item")
                          ClassifiedCard.content-box(:item="item" @openCardContextMenu="openCardContextMenu" @openMarkMenu="openMarkMenu" @openRemarkEditDialog="openRemarkEditDialog"
                            :hiddenIconRemark="false" :hiddenIconMark="false"
                            @triggerOpenPopUpCard="triggerOpenPopUpCard"
                            @triggerClosePopUpCard="triggerClosePopUpCard")
                    v-btn(fab dark absolute bottom right :color="$store.getters['site/siteColor']" :class="{ 'btn-hidden': pageTopBtnStatus.Slide.isHidden }" @click="$vuetify.goTo(0, { container: '#slide-area' })")
                      v-icon(dark) keyboard_arrow_up
              //最近つかった
              v-tab-item
                LoadingWrapper(:isDisplay="isDisplayRecentProgress" :positionFixed="false")
                v-card
                  section#history-area.classified-body-section(ref="HistoryArea")
                    template(v-for="card in recentlyReturnedList")
                      DraggableBox.classified-order-box(:item="card",
                        @dragStart="classifyDragStart",
                        @dragEnd="classifyDragEnd",
                        @dragEnter="classifyDragEnter",
                        @dragLeave="classifyDragLeave",
                        @drop="classifyDrop")
                        template(slot-scope="{ item }" slot="item")
                          ClassifiedCard.content-box(:item="item" :isRecentlyReturned="true" :hiddenIconMark="true" :hiddenIconRemark="true",
                            @openRemarkEditDialog="openRemarkEditDialog" @openCardContextMenu="openCardContextMenu" ,
                            @openMarkMenu="openMarkMenu"
                            @triggerOpenPopUpCard="triggerOpenPopUpCard"
                            @triggerClosePopUpCard="triggerClosePopUpCard")
                    v-btn(fab dark absolute bottom right :color="$store.getters['site/siteColor']" :class="{ 'btn-hidden': pageTopBtnStatus.History.isHidden }" @click="$vuetify.goTo(0, { container: '#history-area' })")
                      v-icon(dark) keyboard_arrow_up

</template>

<script>
import EmergencyErrorDialog from "@/components/common/EmergencyErrorDialog";
import DraggableBox from "@/components/dispatch/DraggableItem";
import ToggleSearchButton from "@/components/common/SearchBoxButton";
import ClassifiedFilterCond from "@/components/dispatch/ClassifiedFilterBox";
import ScheduleFilterBox from "@/components/dispatch/ScheduleFilterBox";
import DriverBox from "@/components/dispatch/DriverBox";
import ClassifiedCard from "@/components/dispatch/ClassifiedCard";
import CardDetailDialog from "@/components/dispatch/DispatchDetail";
import DriverDetailDialog from "@/components/dispatch/DriverDetail";

import ReloadButton from "@/components/common/buttons/ReloadButton";
import RemarkEditDialog from "@/components/dispatch/RemarkEditDialog";
import DispatchTempCardConfirmDialog from "@/components/common/dialog/DispatchTempCardConfirmDialog";
import DispatchSlideReleaseConfirmDialog from "@/components/common/dialog/DispatchSlideReleaseConfirmDialog";
import ClassifiedCardContextMenu from "@/components/dispatch/ClassifiedCardContextMenu";
import DriverContextMenu from "@/components/dispatch/DriverContextMenu";
import ClassifiedCardMarkMenu from "@/components/dispatch/ClassifiedCardMarkMenu";
import ScheduleRelayMenu from "@/components/dispatch/ScheduleRelayMenu";
import VehicleSelectDialog from "@/components/common/select/VehicleSelect";
import SlideEditDialog from "@/components/dispatch/SlideEditDialog";
import SlideReleaseDialog from "@/components/dispatch/SlideReleaseDialog";
import WildCardEditDialog from "@/components/dispatch/WildCardEditDialog";
import MailDialog from "@/components/dispatch/MailSendDialog";
import DispatchRemarkEditDialog from "@/components/dispatch/DispatchRemarkEditDialog";
import ClassifiedCardRemarkEditDialog from "@/components/dispatch/ClassifiedCardRemarkEditDialog";
import DispatchFlowIcon from "@/components/dispatch/DispatchFlowIcon";
import NightLoadingSelectDialog from "@/components/dispatch/NightLoadingSelectDialog";
import LoadingWrapper from "@/components/common/LoadingWrapper";
import ClassifiedCardPopUp from "@/components/dispatch/ClassifiedCardPopUp";
import DispatchLineEntry from "@/components/dispatch/DispatchLineEntry";
import FinanceOverrideWarningDialog from "@/components/common/dialog/FinanceOverrideWarningDialog";
import DeployWarningDialog from "@/components/common/dialog/DeployWarningDialog";
import DeleteConfirmDialog from "@/components/common/dialog/DeleteConfirmDialog";
import DispatchPatternSelectDialog from "@/components/dispatch-pattern/DispatchPatternSelectDialog.vue";

import DatePicker from "@/components/common/DatePicker";
import Axios from "@/assets/js/axios";
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";
import * as Enum from "@/assets/js/enum";
import { LabelName, LimitNumber } from "@/assets/js/constants";

let $http = Axios.http;

export default {
  components: {
    EmergencyErrorDialog,
    DraggableBox,
    ToggleSearchButton,
    ClassifiedFilterCond,
    ScheduleFilterBox,
    DriverBox,
    ClassifiedCard,
    ReloadButton,
    RemarkEditDialog,
    ClassifiedCardContextMenu,
    ClassifiedCardMarkMenu,
    DriverContextMenu,
    ScheduleRelayMenu,
    VehicleSelectDialog,
    SlideEditDialog,
    SlideReleaseDialog,
    DatePicker,
    CardDetailDialog,
    WildCardEditDialog,
    DriverDetailDialog,
    MailDialog,
    DispatchRemarkEditDialog,
    ClassifiedCardRemarkEditDialog,
    DispatchFlowIcon,
    DispatchTempCardConfirmDialog,
    DispatchSlideReleaseConfirmDialog,
    NightLoadingSelectDialog,
    LoadingWrapper,
    ClassifiedCardPopUp,
    DispatchLineEntry,
    FinanceOverrideWarningDialog,
    DeployWarningDialog,
    DeleteConfirmDialog,
    DispatchPatternSelectDialog
  },
  data() {
    return {
      pageTopBtnStatus: {
        Schedule: {
          isHidden: true
        },
        Classified: {
          isHidden: true
        },
        Slide: {
          isHidden: true
        },
        History: {
          isHidden: true
        }
      },
      isDisplayRelay: false,
      dragElement: {
        target: null, // Drop対象
        select: null // Drag対象
      },
      vehicleSelect: {
        label: false,
        code: false,
        errorHandlerMethod: function () {},
        errorHandlerMethodArg: null
      },
      vehicleSelectItem: {
        driverId: null,
        detailId: null,
        isChangeDriverVehicle: true
      },
      flags: {
        classifiedFilterDisplay: false,
        scheduleFilterDisplay: false
      },
      config: {
        dragSelectClass: "dragging-select",
        dragHoverClass: "dragging-hover",
        dragStartFlagClass: "dragging-start"
      },
      htmlBody: {},
      layout: {
        ScheduleLayoutWidth: null,
        ClassifiedLayoutWidth: null
      },
      records: {
        generalClientList: [],
        garageAreaList: []
      },
      cnt: {
        driverScheduleListMax: 100
      },
      //配車表検索条件
      dispatchConditions: {
        scheduledLoadingDate: window.Common.Util.getTomorrowYearMonthDayIsoFormat(),
        specifiedTime: false,
        vehicleType: [],
        vehicleCarryWeight: [],
        vehicleOption: [],
        driverShift: [],
        isFavorite: false,
        isFavoriteOn: false,
        isFavoriteOff: false,
        operatingConditions: [],
        liftType: [],
        contractType: [],
        firstMailType: [],
        allMailType: [],
        isDisplayRestDriver: false,
        shipmentNo: "",
        isComplete: null,
        mark: [],
        clientId: [],
        vehiclGarageArea: [],
        loadingBaseName: "",
        unloadingBaseName: ""
      },
      //区分け検索条件
      classifiedConditions: {
        loadingDate: window.Common.Util.getTomorrowYearMonthDayIsoFormat(),
        specifiedTime: false,
        clientType: [],
        bjiOrderType: [],
        distanceType: [],
        vehicleType: [],
        mark: [],
        relay: false,
        loadingBaseArea: [],
        unloadingBaseArea: [],
        loadingBaseName: "",
        unloadingBaseName: "",
        clientId: [],
        clientBranchId: []
      },
      //空カード定義
      emptyCard: {
        seq: 0,
        dispatchDetailId: null,
        cardId: null,
        cardOrder: null,
        cardRemark: null,
        cardMark: null,
        orderId: null,
        orderType: null,
        vehicleId: null,
        carryWeight: null,
        vehicleTypeCd: null,
        vehicleTypeName: null,
        useChassis: false,
        vehicleNumber: null,
        loadingSpecifiedTimeType: "00",
        loadingRequiredLiftType: "00",
        loadingBaseCount: 0,
        firstLoadingBaseName: null,
        unloadingSpecifiedTimeType: "00",
        unloadingRequiredLiftType: "00",
        unloadingBaseCount: 0,
        firstUnloadingBaseName: null,
        isSlide: false,
        isDayExceed: false,
        isNightLoading: false,
        isBlank: true,
        isReserve: false,
        isRelay: false,
        isFirst: false
      },
      isClassifyDrag: false,
      isRelayItemDrag: false,
      isScheduleFilter: false,
      isClassifiedFilter: false,
      scheduleFilterMenu: false,
      classifiedFilterMenu: false,
      //ローディング制御
      progress: {
        schedule: false,
        reserve: false,
        slide: false,
        recent: false
      },
      labelName: LabelName,
      message: {
        notEditableBillingAndPayment:
          "支払状態・請求状態ともに変更不可のため、カードを変更することは出来ません。",
        notEditablePayment: "支払状態が「発行済」または「確定済」のカードは変更することは出来ません。",
        notEditableBilling: "請求状態が「発行済」または「確定済」のカードは変更することは出来ません。",
        temporaryConfirmedBillingAndPayment:
          "請求状態・支払状態が「仮確定」のカードを変更すると状態が「未確定」に戻ります。内容を変更しますか？",
        temporaryConfirmedPayment:
          "支払状態が「仮確定」のカードを変更すると状態が「未確定」に戻ります。内容を変更しますか？",
        temporaryConfirmedBilling:
          "請求状態が「仮確定」のカードを変更すると状態が「未確定」に戻ります。内容を変更しますか？",
        relayHasResultDeleteConfirm:
          "中継を解除すると、このカードおよび同時に作成した中継先カードに記録されている動態管理データが失われます。解除を続行しますか？"
      },
      // D&D事前確認後の処理用に退避
      dragData: {
        postData: {},
        e: {}
      },
      // 宵積事前確認後の処理用に退避
      nightLoadingItem: {},
      // 中継解除事前確認後の処理用に退避
      relayItem: {}
    };
  },
  computed: {
    ...mapGetters({
      getScheduledLoadingDate: "dispatch/getScheduledLoadingDate"
    }),
    /**
     * 過去日付警告を出すかどうか
     */
    isDisplayDispatchPastWarning() {
      if (!this.dispatchConditions.scheduledLoadingDate) {
        return false;
      }

      const arr = this.dispatchConditions.scheduledLoadingDate.split("-");
      const tableDate = new Date(arr[0], arr[1] - 1, arr[2]);
      const now = new Date();
      const nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      return tableDate < nowDate;
    },
    scheduleList: {
      get() {
        return this.$store.state.dispatch.scheduleList;
      }
    },
    driverTotalCount: {
      get() {
        const count = this.$store.getters["dispatch/getDriverCount"];
        if (count < 1) {
          return "";
        }

        return "(" + count + "名)";
      }
    },
    relayList: {
      get() {
        let relayList = [];
        for (var idx in this.$store.state.dispatch.relayList) {
          relayList.push(this.$store.state.dispatch.relayList[idx]);
        }

        let relayCard = Object.assign({}, this.emptyCard);
        relayCard.seq = relayList.length;
        relayList.push(relayCard);

        for (idx in relayList) {
          relayList[idx]._relayCard = true;
        }

        return relayList;
      }
    },
    reserveList: {
      get() {
        return this.$store.state.dispatch.reserveList;
      }
    },
    slideList: {
      get() {
        return this.$store.state.dispatch.slideList;
      }
    },
    recentlyReturnedList: {
      get() {
        return this.$store.state.dispatch.recentlyReturnedList;
      }
    },
    generalClientList: {
      get() {
        return this.records.generalClientList;
      }
    },
    garageAreaList: {
      get() {
        return this.records.garageAreaList;
      }
    },
    driverScheduleListMaxCount: {
      get() {
        return this.cnt.driverScheduleListMax;
      }
    },
    getClassifiedCardCount() {
      return this.$store.state.dispatch.reserveListCount;
    },
    getSlideClassifiedCardCount() {
      return this.$store.state.dispatch.slideListCount;
    },
    getHistoryClassifiedCardCount() {
      return this.$store.state.dispatch.recentlyReturnedListCount;
    },
    //配車表ローディング表示可否
    isDisplayScheduleProgress() {
      return this.progress.schedule;
    },
    //区分け表ローディング表示可否
    isDisplayReserveProgress() {
      return this.progress.reserve;
    },
    //スライドローディング表示可否
    isDisplaySlideProgress() {
      return this.progress.slide;
    },
    //最近使ったローディング表示可否
    isDisplayRecentProgress() {
      return this.progress.recent;
    }
  },
  created() {
    this.$store.commit("dispatch/setScheduledLoadingDate", this.dispatchConditions.scheduledLoadingDate);
    this.$store.commit("dispatch/clearDispatchData");
    // this.reload(); // 他条件初期化に伴い、リロードされるのでここでは不要
  },
  mounted() {
    // destroyed()では[this.$refs.ScheduleArea]がundefinedになるので最初に入れておく
    this.$refs.ScheduleArea.removeEventListener("scroll", this.handleScrollSchedule);
    this.$refs.ClassifiedArea.removeEventListener("scroll", this.handleScrollClassified);
    this.$refs.SlideArea.removeEventListener("scroll", this.handleScrollSlide);
    this.$refs.HistoryArea.removeEventListener("scroll", this.handleScrollHistory);
    window.removeEventListener("mousemove", this.startResize, false);
    window.removeEventListener("mouseup", this.stopResize, false);

    this.$refs.ScheduleArea.addEventListener("scroll", this.handleScrollSchedule);
    this.$refs.ClassifiedArea.addEventListener("scroll", this.handleScrollClassified);
    this.$refs.SlideArea.addEventListener("scroll", this.handleScrollSlide);
    this.$refs.HistoryArea.addEventListener("scroll", this.handleScrollHistory);

    //配車画面をリロードする必要がある際にコールする
    window.removeEventListener(window.Common.Config.observer.onRequireDispatchReload, this.reload);
    window.addEventListener(window.Common.Config.observer.onRequireDispatchReload, this.reload, false);
  },
  methods: {
    ...mapMutations({
      incrementDispatchTablePageCount: "dispatch/incrementDispatchTablePageCount",
      clearDispatchTablePageCount: "dispatch/clearDispatchTablePageCount",
      incrementReserveTablePageCount: "dispatch/incrementReserveTablePageCount",
      clearReserveTablePageCount: "dispatch/clearReserveTablePageCount",
      incrementSlideTablePageCount: "dispatch/incrementSlideTablePageCount",
      clearSlideTablePageCount: "dispatch/clearSlideTablePageCount",
      incrementRecentlyReturnedTablePageCount: "dispatch/incrementRecentlyReturnedTablePageCount",
      clearRecentlyReturnedTablePageCount: "dispatch/clearRecentlyReturnedTablePageCount"
    }),
    ...mapActions({
      mergeDriverRemark: "dispatch/mergeDriverRemark",
      setDispatchPatternDateInfo: "dispatchPattern/setDateInfo"
    }),
    onSaveRemark(data) {
      this.mergeDriverRemark(data);
    },
    /**
     * エラーメッセージ
     */
    openErrorMsgBox(message) {
      this.$refs.errorMsg.open(message);
    },
    /**
     * 配車表スクロールイベント
     */
    handleScrollSchedule() {
      if (this.$refs.ScheduleArea !== undefined) {
        const clientHeight = this.$refs.ScheduleArea.clientHeight;
        const scrollTop = this.$refs.ScheduleArea.scrollTop;
        const scrollHeight = this.$refs.ScheduleArea.scrollHeight;

        if (scrollHeight - clientHeight - scrollTop < LimitNumber.ScrollNumber && !this.progress.schedule) {
          this.incrementDispatchTablePageCount();
          this.loadSchedule();
        }

        //ページトップボタンの表示制御
        this.pageTopBtnStatus.Schedule.isHidden = this.$refs.ScheduleArea.scrollTop <= 200;
      }
    },
    /**
     * 区分けエリアスクロールイベント
     */
    handleScrollClassified() {
      if (this.$refs.ClassifiedArea !== undefined) {
        const clientHeight = this.$refs.ClassifiedArea.clientHeight;
        const scrollTop = this.$refs.ClassifiedArea.scrollTop;
        const scrollHeight = this.$refs.ClassifiedArea.scrollHeight;

        if (scrollHeight - clientHeight - scrollTop < LimitNumber.ScrollNumber) {
          this.incrementReserveTablePageCount();
          this.loadReserve();
        }

        //ページトップボタンの表示制御
        this.pageTopBtnStatus.Classified.isHidden = this.$refs.ClassifiedArea.scrollTop <= 200;
      }
    },
    handleScrollSlide() {
      if (this.$refs.SlideArea !== undefined) {
        const clientHeight = this.$refs.SlideArea.clientHeight;
        const scrollTop = this.$refs.SlideArea.scrollTop;
        const scrollHeight = this.$refs.SlideArea.scrollHeight;

        if (scrollHeight - clientHeight - scrollTop < LimitNumber.ScrollNumber) {
          this.incrementSlideTablePageCount();
          this.loadSlide();
        }

        //ページトップボタンの表示制御
        this.pageTopBtnStatus.Slide.isHidden = this.$refs.SlideArea.scrollTop <= 200;
      }
    },
    handleScrollHistory() {
      if (this.$refs.HistoryArea !== undefined) {
        const clientHeight = this.$refs.HistoryArea.clientHeight;
        const scrollTop = this.$refs.HistoryArea.scrollTop;
        const scrollHeight = this.$refs.HistoryArea.scrollHeight;

        if (scrollHeight - clientHeight - scrollTop < LimitNumber.ScrollNumber) {
          this.incrementRecentlyReturnedTablePageCount();
          this.loadRecentlyReturned();
        }

        //ページトップボタンの表示制御
        this.pageTopBtnStatus.History.isHidden = this.$refs.HistoryArea.scrollTop <= 200;
      }
    },
    clearClassifiedAreaScrollTop() {
      this.$refs.ClassifiedArea.scrollTop = 0;
    },
    clearScheduleAreaScrollTop() {
      this.$refs.ScheduleArea.scrollTop = 0;
    },
    /**
     * クラスをセット
     * @param target
     * @param className
     */
    setClass(target, className) {
      if (target.classList.contains(className)) {
        return;
      }
      target.classList.toggle(className);
    },
    /**
     * クラスを削除
     * @param target
     * @param className
     */
    removeClass(target, className) {
      if (target.classList.contains(className)) {
        target.classList.toggle(className);
      }
    },

    /**
     * リサイズ初期処理
     */
    initResize() {
      window.addEventListener("mousemove", this.startResize, false);
      window.addEventListener("mouseup", this.stopResize, false);
    },
    startResize(e) {
      let wrapWidth = this.$refs.WrapLayout.getBoundingClientRect().width;
      let offset =
        e.clientX - parseInt(document.getElementsByClassName("v-navigation-drawer")[0].style.width);
      let scheduleLayoutWidth = offset - this.$refs.ScheduleLayout.offsetLeft;
      let classifiedLayoutWidth = wrapWidth - scheduleLayoutWidth;
      if (wrapWidth - 100 > scheduleLayoutWidth && wrapWidth - 100 > classifiedLayoutWidth) {
        this.layout.ScheduleLayoutWidth = (scheduleLayoutWidth / wrapWidth) * 100 + "%";
        this.layout.ClassifiedLayoutWidth = (classifiedLayoutWidth / wrapWidth) * 100 + "%";
      }
    },
    stopResize(e) {
      window.removeEventListener("mousemove", this.startResize, false);
    },
    openDriverContextMenu(driver) {
      this.$refs.DriverContextMenu.open(driver);
    },
    /**
     * 備考ダイアログ
     * @param item
     */
    openDriverRemarkEditDialog(item) {
      this.$refs.DispatchRemarkEditDialog.openDialog(item);
    },
    /**
     * カード備考ダイアログ
     * */
    openRemarkEditDialog(item) {
      this.$refs.ClassifiedCardRemarkEditDialog.openDialog({
        item: item.item,
        loadingDate: this.dispatchConditions.scheduledLoadingDate
      });
    },
    /**
     * 車両変更用ダイアログを表示
     * @param item
     * */
    openVehicleSelectDialog(item) {
      this.vehicleSelectItem.driverId = item.driverId;
      this.vehicleSelectItem.isChangeDriverVehicle = true;
      let cloneItem = _.cloneDeep(item);
      cloneItem.item.vehicleId = item.item.firstCard.vehicleId;
      this.$refs.VehicleSelectDialog.openDialog(cloneItem, this.dispatchConditions.scheduledLoadingDate);
    },
    /**
     * 車両変更用ダイアログを表示
     * @param item
     * */
    openCardVehicleSelectDialog(item) {
      this.vehicleSelectItem.detailId = item.item.dispatchDetailId;
      this.vehicleSelectItem.isChangeDriverVehicle = false;
      this.$refs.VehicleSelectDialog.openDialog(item, this.dispatchConditions.scheduledLoadingDate);
    },
    /**
     * スライド編集用ダイアログを表示
     * @param item
     * */
    openSlideEditDialog(item) {
      this.$refs.SlideEditDialog.openDialog({
        item: item,
        loadingDate: this.dispatchConditions.scheduledLoadingDate
      });
    },
    /**
     * スライド解除用ダイアログを表示
     * @param item
     * */
    openSlideReleaseDialog(item) {
      if (item.data.dispatchDetailId !== null) {
        this.$refs.SlideReleaseDialog.openDialog({
          item: item,
          loadingDate: this.dispatchConditions.scheduledLoadingDate
        });
      } else {
        this.$refs.DispatchSlideReleaseConfirmDialog.openDialog({
          orderId: item.data.orderId,
          orderType: item.data.orderType,
          cardId: item.data.cardId,
          scheduledLoadingDate: this.dispatchConditions.scheduledLoadingDate,
          withAfter: false
        });
      }
    },
    /**
     * ワイルドカード編集用ダイアログを表示
     * */
    openWildCardEditDialog(param) {
      this.$refs.WildCardEditDialog.openDialog({
        driverId: param.driverId,
        cardOrder: param.item.cardOrder,
        orderType: param.item.orderType,
        orderId: param.item.orderId,
        cardId: param.item.cardId
      });
    },
    /**
     * 車両変更用ダイアログ選択時
     * @param item
     * */
    selectVehicleSelectDialog(item) {
      if (this.vehicleSelectItem.driverId && this.vehicleSelectItem.isChangeDriverVehicle) {
        this.changeVehicle();
      }
      if (this.vehicleSelectItem.detailId && !this.vehicleSelectItem.isChangeDriverVehicle) {
        this.changeCardVehicle();
      }
    },
    /**
     * 乗務員車両変更
     * @param item
     * */
    changeVehicle(item) {
      let postData = {
        scheduleLoadingDate: this.dispatchConditions.scheduledLoadingDate,
        driverId: this.vehicleSelectItem.driverId,
        vehicleId: this.vehicleSelect.code
      };
      const self = this;
      $http
        .post(self.$config.urls.postChangeVehicle, postData)
        .then(() => {
          this.vehicleSelectItem.driverId = null;
          this.reloadSchedule();
        })
        .catch(error => {
          this.openErrorMsgBox(error.response.data.message);
        });
    },
    /**
     * カード車両変更用ダイアログ選択
     * @param item
     * */
    changeCardVehicle(item) {
      let postData = {
        detailId: this.vehicleSelectItem.detailId,
        vehicleId: this.vehicleSelect.code
      };
      const self = this;
      $http
        .post(self.$config.urls.postChangeCardVehicle, postData)
        .then(() => {
          this.vehicleSelectItem.detailId = null;
          this.reloadSchedule();
        })
        .catch(error => {
          this.openErrorMsgBox(error.response.data.message);
        });
    },
    /**
     * スライド保存後処理
     * @param item
     * */
    savedSlide() {
      this.reloadSchedule();
      this.reloadReserve();
      this.reloadSlide();
    },
    /**
     * スライド解除後処理
     * @param item
     * */
    releasedSlide() {
      this.reloadSchedule();
      this.reloadReserve();
      this.reloadSlide();
    },
    /**
     * メール送信ダイアログを表示
     */
    openMailDialog() {
      this.$refs.MailDialog.openDialog(this.dispatchConditions);
    },
    /**
     * カード詳細ダイアログを表示
     * @param item
     */
    openCardDetailDialog(item) {
      if (item.isSlide) {
        this.openSlideCardDetailDialog(item);
        return;
      }
      this.$refs.CardDetailDialog.openDialog(item);
    },
    /**
     * スライド蘭カード詳細ダイアログを表示
     * @param item
     */
    openSlideCardDetailDialog(item) {
      // スライド蘭からスライドした配車日=スライド日のカード対応
      const slideCardId =
        item.firstSlideDate === this.dispatchConditions.scheduledLoadingDate
          ? item.parentCardId
          : item.cardId;
      this.$set(item, "slideCardId", slideCardId);
      this.$refs.CardDetailDialog.openDialog(item);
    },
    openDispatchDriverSelectDialog() {
      this.$refs.DispatchLineEntry.openDialog(this.dispatchConditions.scheduledLoadingDate);
    },
    /**
     * カード詳細ダイアログ保存後処理
     * @param item
     */
    savedCardDetailDialog(data) {
      this.reloadSchedule();
      const orderData = {
        loadingDate: this.classifiedConditions.loadingDate,
        orderId: data.orderId,
        orderType: data.orderType
      };
      this.$store.dispatch("dispatch/fetchReserveOrder", orderData);
      this.$store.dispatch("dispatch/fetchRecentlyReturnedOrder", orderData);
      this.$store.dispatch("dispatch/fetchReserveSlideOrder", orderData);
    },
    /**
     * カードのコンテキストメニューを表示
     * @param item
     */
    openCardContextMenu(item) {
      this.$refs.ClassifiedCardContextMenu.open(item);
    },
    /**
     * 乗務員のお気に入り切り替え
     * @param item
     */
    toggleFavorite(item) {
      let postData = {
        scheduledLoadingDate: this.dispatchConditions.scheduledLoadingDate,
        driverId: item.driver.driverId,
        isFavorite: !item.favorite
      };
      const self = this;
      $http
        .post(self.$config.urls.postSaveDispatchFavorite, postData)
        .then(() => {
          item.driver.isFavorite = postData.isFavorite;
        })
        .catch(error => {
          this.openErrorMsgBox(error.response.data.message);
        });
    },
    /**
     * 乗務員詳細ダイアログを表示
     * @param item
     */
    openDriverDetailDialog(item) {
      this.$refs.DriverDetailDialog.openDialog(item);
    },
    /**
     * マーク用メニューを開く
     * @param item
     */
    openMarkMenu(item) {
      this.$refs.ClassifiedCardMarkMenu.open(item);
    },
    /**
     * スケジュールの中継メニューを表示
     */
    openRelayMenu() {
      this.$refs.ScheduleRelayMenu.openDialog(
        this.$store.state.dispatch.relayList,
        this.dispatchConditions.scheduledLoadingDate
      );
    },
    /**
     * 中継設定の保存
     * @param item
     */
    savedRelay() {
      this.reloadReserve();
      this.reloadSlide();
      this.reloadRecentlyReturned();
    },
    hideRelay(isSaved) {
      this.isDisplayRelay = false;
      if (isSaved) {
        this.isDisplayRelay = true;
      }
    },
    cancelRelayPre(item) {
      if (item != null) {
        this.relayItem = item;
        this.$store
          .dispatch("dispatch/postCheckDispatchRelayRelease", item)
          .then(value => {
            if (value === true) {
              this.$refs.DeleteConfirmDialog.openDialog();
            } else {
              this.cancelRelay();
            }
          })
          .catch(reason => {
            this.openErrorMsgBox(reason.message);
          });
      }
    },
    /**
     * 中継解除
     */
    cancelRelay() {
      if (this.relayItem != null) {
        this.$store
          .dispatch("dispatch/postDispatchRelayRelease", this.relayItem)
          .then(() => {
            this.reload();
          })
          .catch(reason => {
            this.openErrorMsgBox(reason.message);
          });
      }
    },
    /**
     * 宵積事前確認
     */
    async setNightLoadingPre(item) {
      this.nightLoadingItem = item;
      var itemData = {
        data: item.card
      };
      const postData = {
        from: itemData,
        to: itemData,
        empty: this.emptyCard,
        opt: { between: false, classifyWrap: false, slideWrap: false }
      };
      await this.$store.dispatch("dispatch/checkDragDrop", postData).then(value => {
        if (value.paymentStatusTemporaryConfirmed && value.billingStatusTemporaryConfirmed) {
          this.$refs.FinanceOverrideWarningDialogNightLoading.openDialogWithMessage(
            this.message.temporaryConfirmedBillingAndPayment
          );
        } else if (value.paymentStatusTemporaryConfirmed) {
          this.$refs.FinanceOverrideWarningDialogNightLoading.openDialogWithMessage(
            this.message.temporaryConfirmedPayment
          );
        } else if (value.billingStatusTemporaryConfirmed) {
          this.$refs.FinanceOverrideWarningDialogNightLoading.openDialogWithMessage(
            this.message.temporaryConfirmedBilling
          );
        } else {
          this.$refs.FinanceOverrideWarningDialogNightLoading.yes();
        }
      });
    },
    /**
     * 宵積設定
     */
    setNightLoading() {
      const item = this.nightLoadingItem;
      const targetDriverId = item.driverId;
      const driverIds = [targetDriverId];
      this.$store
        .dispatch("dispatch/postSetNightLoading", item)
        .then(value => {
          if (value.data !== null && value.data.length > 0) {
            // 宵積みが複数ある場合は選択ダイアログを表示
            this.$refs.NightLoadingSelectDialog.openDialog({
              item: value.data,
              driverId: targetDriverId
            });
          } else {
            this.fetchAndMergeSpecifiedDriver(driverIds);
          }
        })
        .catch(reason => {
          this.openErrorMsgBox(reason.message);
        });
    },
    /**
     * 宵積選択
     * @param item
     */
    selectNightLoading(item) {
      const driverIds = [item.driverId];
      this.fetchAndMergeSpecifiedDriver(driverIds);
    },
    /**
     * 宵積解除事前確認
     */
    async unSetNightLoadingPre(item) {
      this.nightLoadingItem = item;
      var itemData = {
        data: item.card
      };
      const postData = {
        from: itemData,
        to: itemData,
        empty: this.emptyCard,
        opt: { between: false, classifyWrap: false, slideWrap: false }
      };
      await this.$store.dispatch("dispatch/checkDragDrop", postData).then(value => {
        if (value.paymentStatusTemporaryConfirmed && value.billingStatusTemporaryConfirmed) {
          this.$refs.FinanceOverrideWarningDialogNightLoadingUnSet.openDialogWithMessage(
            this.message.temporaryConfirmedBillingAndPayment
          );
        } else if (value.paymentStatusTemporaryConfirmed) {
          this.$refs.FinanceOverrideWarningDialogNightLoadingUnSet.openDialogWithMessage(
            this.message.temporaryConfirmedPayment
          );
        } else if (value.billingStatusTemporaryConfirmed) {
          this.$refs.FinanceOverrideWarningDialogNightLoadingUnSet.openDialogWithMessage(
            this.message.temporaryConfirmedBilling
          );
        } else {
          this.$refs.FinanceOverrideWarningDialogNightLoadingUnSet.yes();
        }
      });
    },
    /**
     * 宵積解除
     */
    unSetNightLoading() {
      const item = this.nightLoadingItem;
      const driverIds = [item.driverId];
      this.$store
        .dispatch("dispatch/postUnSetNightLoading", item)
        .then(value => {
          this.fetchAndMergeSpecifiedDriver(driverIds);
        })
        .catch(reason => {
          this.openErrorMsgBox(reason.message);
        });
    },
    /**
     * 配車表リロードボタン押下
     */
    clickReloadButtonForSchedule() {
      this.clearScheduleAreaScrollTop();
      this.reloadSchedule();
      this.reloadSlide();
      this.reloadRecentlyReturned();
    },
    /**
     * 配車表リロードボタン押下
     */
    clickReloadButtonForClassify() {
      this.reloadReserve();
    },
    reload() {
      this.reloadSchedule();
      this.reloadReserve();
      this.reloadSlide();
      this.reloadRecentlyReturned();
    },
    /**
     * 配車画面の左側、乗務員と乗務員に紐付くデータのリロード
     */
    reloadSchedule() {
      this.clearDispatchTablePageCount();
      this.loadSchedule();
    },
    /**
     * 仮カード作成後、該当の乗務員レーンだけ更新する
     * @param item
     */
    reloadDriverSchedule(item) {
      if (item !== undefined && item.driverId) {
        this.fetchAndMergeSpecifiedDriver([item.driverId]);
      }
    },
    loadSchedule() {
      this.progress.schedule = true;
      this.$store
        .dispatch("dispatch/fetchScheduleList", this.dispatchConditions)
        .then(() => {
          let cnt = 0;
          if (this.$store.state.dispatch.scheduleList.length > 0) {
            cnt = this.$store.state.dispatch.scheduleList[0].driverTotalCount;
          }
          this.$store.commit("dispatch/setDriverCount", cnt);
        })
        .finally(() => {
          this.progress.schedule = false;
        });
    },
    /**
     * 配車画面の右側、未配車のデータのリロード
     */
    reloadReserve() {
      this.clearReserveTablePageCount();
      this.loadReserve();
      this.clearClassifiedAreaScrollTop();
      this.$store.state.dispatch.relayList = []; // 中継エリアクリア
    },
    loadReserve() {
      this.progress.reserve = true;
      this.$store.dispatch("dispatch/fetchReserveList", this.classifiedConditions).finally(() => {
        this.progress.reserve = false;
      });
    },
    /**
     * 配車画面の右側、未配車のスライドデータのリロード
     */
    reloadSlide() {
      this.clearSlideTablePageCount();
      this.loadSlide();
    },
    loadSlide() {
      this.progress.slide = true;
      this.$store.dispatch("dispatch/fetchReserveSlideList").finally(() => {
        this.progress.slide = false;
      });
    },
    /**
     * 配車画面の右側、最近戻したカードデータのリロード
     */
    reloadRecentlyReturned() {
      this.clearRecentlyReturnedTablePageCount();
      this.loadRecentlyReturned();
    },
    loadRecentlyReturned() {
      this.progress.recent = true;
      this.$store.dispatch("dispatch/fetchRecentlyReturnedList").finally(() => {
        this.progress.recent = false;
      });
    },
    /**
     * 配車フィルタ用荷主のリロード
     */
    reloadDispatchedClient() {
      this.$store
        .dispatch("dispatch/fetchClientDispatchFilter", this.dispatchConditions.scheduledLoadingDate)
        .then(value => {
          this.records.generalClientList = value;
        });
    },
    /**
     * 配車フィルタ用車庫エリアのリロード
     */
    reloadGarageArea() {
      this.$store
        .dispatch("area/fetchScheduleGarageArea", this.dispatchConditions.scheduledLoadingDate)
        .then(value => {
          this.records.garageAreaList = value;
        });
    },
    /**
     * 指定乗務員行のみリロードしてマージ
     */
    async fetchAndMergeSpecifiedDriver(driverIds) {
      this.progress.schedule = true;
      return this.$store.dispatch("dispatch/fetchScheduleListSpecifiedDriver", driverIds).finally(() => {
        this.progress.schedule = false;
      });
    },
    /**
     * 配車表 表示起点日の変更
     * @param changeDays
     */
    changeScheduledLoadingDate(changeDays) {
      const date = new Date(this.dispatchConditions.scheduledLoadingDate);
      date.setDate(date.getDate() + changeDays);
      this.dispatchConditions.scheduledLoadingDate = window.Common.Util.getYearMonthDayIsoFormat(date);
    },
    /**
     * 区分け表 表示起点日の変更
     * @param changeDays
     */
    changeLoadingDate(changeDays) {
      const date = new Date(this.classifiedConditions.loadingDate);
      date.setDate(date.getDate() + changeDays);
      this.classifiedConditions.loadingDate = window.Common.Util.getYearMonthDayIsoFormat(date);
    },
    /**
     * スケジュール表側のカードのドラッグ開始時イベント
     * @param item
     * @param e
     */
    driverDragStart(item, e) {
      this._dragStart(item, e);
    },
    /**
     * スケジュール表側のカードのドラッグ終了時イベント
     * @param item
     * @param e
     */
    driverDragEnd(item, e) {
      this._dragEnd(item, e);
    },
    /**
     * スケジュール表側のカードをドラッグ中、ドロップ要素に入った時のイベント
     * @param item
     * @param e
     */
    driverDragEnter(item, e) {
      this._dragEnter(item, e);
    },
    /**
     * スケジュール表側の中継カードのドラッグ開始時イベント
     * @param item
     * @param e
     */
    driverDragStartRelay(item, e) {
      this._dragStart(item, e);
      this.isRelayItemDrag = true;
    },
    /**
     * スケジュール表側の中継カードのドラッグ終了時イベント
     * @param item
     * @param e
     */
    driverDragEndRelay(item, e) {
      this._dragEnd(item, e);
      this.isRelayItemDrag = false;
    },
    /**
     * スケジュール表側のカードをドラッグ中、ドロップ要素(中継)に入った時のイベント
     * @param item
     * @param e
     */
    driverDragEnterRelay(item, e) {
      if (this._isRelay(this.dragElement.select) || !this._isClassifiedItem(this.dragElement.select)) {
        // 中継カードまたは区分けカード以外はドラッグできない
        e.preventDefault();
        return;
      }
      this._dragEnter(item, e);
    },
    /**
     * スケジュール表側のカードをドラッグ中、ドロップ要素から出た時のイベント
     * @param item
     * @param e
     */
    driverDragLeave(item, e) {
      this._dragLeave(item, e);
    },
    /**
     * スケジュール表側のカードをドロップ対象で離した時 事前確認
     * @param item
     * @param e
     */
    async driverDropPre(item, e) {
      if (this.dragElement.select != null) {
        // 自分自身にDropされたら何もしない
        if (this._isSelf(item)) {
          e.preventDefault();
          return;
        }

        const postData = {
          from: this.dragElement.select,
          to: item,
          empty: this.emptyCard,
          opt: { between: false, classifyWrap: false, slideWrap: false }
        };
        // 事前に確認後処理の引数を退避
        this.dragData.postData = postData;
        this.dragData.e = e;

        await this.$store.dispatch("dispatch/checkDragDrop", postData).then(value => {
          if (value.notEditablePaymentStatus && value.notEditableBillingStatus) {
            this.openErrorMsgBox(this.message.notEditableBillingAndPayment);
            this.removeClass(e.target, this.config.dragHoverClass);
          } else if (value.notEditablePaymentStatus) {
            this.openErrorMsgBox(this.message.notEditablePayment);
            this.removeClass(e.target, this.config.dragHoverClass);
          } else if (value.notEditableBillingStatus) {
            this.openErrorMsgBox(this.message.notEditableBilling);
            this.removeClass(e.target, this.config.dragHoverClass);
          } else if (value.manualBasicRate) {
            this.$refs.DeployWarningDialog.openDialog();
            this.removeClass(e.target, this.config.dragHoverClass);
          } else if (value.paymentStatusTemporaryConfirmed && value.billingStatusTemporaryConfirmed) {
            this.$refs.FinanceOverrideWarningDialogDriver.openDialogWithMessage(
              this.message.temporaryConfirmedBillingAndPayment
            );
            this.removeClass(e.target, this.config.dragHoverClass);
          } else if (value.paymentStatusTemporaryConfirmed) {
            this.$refs.FinanceOverrideWarningDialogDriver.openDialogWithMessage(
              this.message.temporaryConfirmedPayment
            );
            this.removeClass(e.target, this.config.dragHoverClass);
          } else if (value.billingStatusTemporaryConfirmed) {
            this.$refs.FinanceOverrideWarningDialogDriver.openDialogWithMessage(
              this.message.temporaryConfirmedBilling
            );
            this.removeClass(e.target, this.config.dragHoverClass);
          } else {
            this.$refs.FinanceOverrideWarningDialogDriver.yes();
          }
        });
      }
    },
    /**
     * スケジュール表側のカードをドロップ対象で離した時
     */
    driverDrop() {
      const postData = this.dragData.postData;

      //DropEndでthis.dragElement.selectを初期化するのであらかじめ取っておく
      const driverIds = [postData.from.driverId, postData.to.driverId];

      this.$store
        .dispatch("dispatch/dragAndDrop", postData)
        .then(() => {
          this.fetchAndMergeSpecifiedDriver(driverIds);
          this.reloadReserve();
          this.reloadSlide();
          this.reloadRecentlyReturned();
        })
        .catch(reason => {
          this.openErrorMsgBox(reason.message);
        });

      this.removeClass(this.dragData.e.target, this.config.dragHoverClass);
      // 退避データのクリア
      this.dragData.postData = {};
      this.dragData.e = {};
    },
    /**
     * 日跨ぎエリアにドロップした時 事前確認
     * @param item
     * @param e
     */
    async driverDayExceedDropPre(item, e) {
      if (this.dragElement.select != null) {
        // 自分自身にDropされたら何もしない
        if (this._isSelf(item)) {
          e.preventDefault();
          return;
        }

        const postData = {
          from: this.dragElement.select,
          to: item,
          empty: this.emptyCard,
          opt: { between: false, classifyWrap: false, slideWrap: false }
        };
        // 事前に確認後処理の引数を退避
        this.dragData.postData = postData;
        this.dragData.e = e;

        await this.$store.dispatch("dispatch/checkDragDrop", postData).then(value => {
          if (value.notEditablePaymentStatus && value.notEditableBillingStatus) {
            this.openErrorMsgBox(this.message.notEditableBillingAndPayment);
          } else if (value.notEditablePaymentStatus) {
            this.openErrorMsgBox(this.message.notEditablePayment);
          } else if (value.notEditableBillingStatus) {
            this.openErrorMsgBox(this.message.notEditableBilling);
          } else if (value.manualBasicRate) {
            this.$refs.DeployWarningDialogExceed.openDialog();
          } else if (value.paymentStatusTemporaryConfirmed && value.billingStatusTemporaryConfirmed) {
            this.$refs.FinanceOverrideWarningDialogExceed.openDialogWithMessage(
              this.message.temporaryConfirmedBillingAndPayment
            );
          } else if (value.paymentStatusTemporaryConfirmed) {
            this.$refs.FinanceOverrideWarningDialogExceed.openDialogWithMessage(
              this.message.temporaryConfirmedPayment
            );
          } else if (value.billingStatusTemporaryConfirmed) {
            this.$refs.FinanceOverrideWarningDialogExceed.openDialogWithMessage(
              this.message.temporaryConfirmedBilling
            );
          } else {
            this.$refs.FinanceOverrideWarningDialogExceed.yes();
          }
        });

        this.removeClass(e.target, this.config.dragHoverClass);
      }
    },
    /**
     * 日跨ぎエリアにドロップした時
     * @param item
     * @param e
     */
    driverDayExceedDrop() {
      const postData = this.dragData.postData;

      //DropEndでthis.dragElement.selectを初期化するのであらかじめ取っておく
      const targetDriverId = postData.to.driverId;
      const driverIds = [postData.from.driverId, targetDriverId];

      this.$store
        .dispatch("dispatch/dragAndDropDayExceed", postData)
        .then(response => {
          this.fetchAndMergeSpecifiedDriver(driverIds);
          this.reloadReserve();
          this.reloadSlide();
          this.reloadRecentlyReturned();

          if (response.canCreate) {
            this.$refs.DispatchTempCardConfirmDialog.openDialog({
              driverId: targetDriverId,
              scheduledLoadingDate: response.targetDate
            });
          }
        })
        .catch(reason => {
          this.openErrorMsgBox(reason.message);
        });

      this.removeClass(this.dragData.e.target, this.config.dragHoverClass);
      // 退避をクリア
      this.dragData.postData = {};
      this.dragData.e = {};
    },
    /**
     * スケジュール表側のカードをドロップ対象(中継)で離した時
     * @param item
     * @param e
     */
    driverDropRelay(item, e) {
      if (
        this.dragElement.select != null &&
        !this._isRelay(this.dragElement.select) &&
        this._isClassifiedItem(this.dragElement.select)
      ) {
        // 自分自身にDropされたら何もしない、中継カードまたは区分けカード以外はドラッグできない
        if (this._isSelf(item)) {
          e.preventDefault();
          return;
        }
        this.$store.commit("dispatch/swap", {
          from: this.dragElement.select,
          to: item,
          empty: this.emptyCard,
          opt: { between: false, classifyWrap: false, slideWrap: false }
        });
      }

      this.removeClass(e.target, this.config.dragHoverClass);
      this.isClassifyDrag = false;
    },

    /**
     * 区分け表のドラッグ開始時イベント
     * @param item
     * @param e
     */
    classifyWrapDragStart(item, e) {
      if (!this.isClassifyDrag) {
        e.preventDefault();
      }
    },
    /**
     * 区分け表に入った時のイベント
     * @param item
     * @param e
     */
    classifyWrapDragEnter(item, e) {
      // 自分自身か区分け欄にDropされたら何もしない
      if (this._isClassifiedItem(this.dragElement.select)) {
        e.preventDefault();
        return;
      }
      // 宵済みカードはDropされたら何もしない
      if (this._isDispatch(this.dragElement.select) && this._isNightLoading(this.dragElement.select)) {
        e.preventDefault();
        return;
      }

      this._dragEnter(item, e);
    },
    /**
     * 区分け表から出た時のイベント
     * @param item
     * @param e
     */
    classifyWrapDragLeave(item, e) {
      this._dragLeave(item, e);
    },
    /**
     * 区分け表で離した時 事前確認
     * @param item
     * @param e
     */
    async classifyWrapDropPre(item, e) {
      if (this.dragElement.select != null) {
        // 自分自身か区分け欄にDropされたら何もしない
        if (this._isClassifiedItem(this.dragElement.select)) {
          e.preventDefault();
          return;
        }
        // 宵済みカードはDropされたら何もしない
        if (this._isDispatch(this.dragElement.select) && this._isNightLoading(this.dragElement.select)) {
          e.preventDefault();
          return;
        }
        if (this.isRelayItemDrag) {
          this.$store.commit("dispatch/swap", {
            from: this.dragElement.select,
            to: item,
            empty: this.emptyCard,
            opt: { between: false, classifyWrap: true, slideWrap: false }
          });
          this.isRelayItemDrag = false;
        } else {
          const postData = {
            from: this.dragElement.select,
            to: item,
            empty: this.emptyCard,
            opt: { between: false, classifyWrap: true, slideWrap: false }
          };
          // 事前に確認後処理の引数を退避
          this.dragData.postData = postData;
          this.dragData.e = e;

          await this.$store.dispatch("dispatch/checkDragDrop", postData).then(value => {
            if (value.notEditablePaymentStatus && value.notEditableBillingStatus) {
              this.openErrorMsgBox(this.message.notEditableBillingAndPayment);
            } else if (value.notEditablePaymentStatus) {
              this.openErrorMsgBox(this.message.notEditablePayment);
            } else if (value.notEditableBillingStatus) {
              this.openErrorMsgBox(this.message.notEditableBilling);
            } else if (value.paymentStatusTemporaryConfirmed && value.billingStatusTemporaryConfirmed) {
              this.$refs.FinanceOverrideWarningDialogClassified.openDialogWithMessage(
                this.message.temporaryConfirmedBillingAndPayment
              );
            } else if (value.paymentStatusTemporaryConfirmed) {
              this.$refs.FinanceOverrideWarningDialogClassified.openDialogWithMessage(
                this.message.temporaryConfirmedPayment
              );
            } else if (value.billingStatusTemporaryConfirmed) {
              this.$refs.FinanceOverrideWarningDialogClassified.openDialogWithMessage(
                this.message.temporaryConfirmedBilling
              );
            } else {
              this.$refs.FinanceOverrideWarningDialogClassified.yes();
            }
          });
          this.removeClass(e.target, this.config.dragHoverClass);
        }
      }
    },
    /**
     * 区分け表で離した時
     * @param item
     * @param e
     */
    classifyWrapDrop() {
      const postData = this.dragData.postData;

      //DropEndでthis.dragElement.selectを初期化するのであらかじめ取っておく
      const driverIds = [postData.from.driverId, postData.to.driverId];

      this.$store
        .dispatch("dispatch/dragAndDropRemove", postData)
        .then(() => {
          this.fetchAndMergeSpecifiedDriver(driverIds);
          this.reloadReserve();
          this.reloadSlide();
          this.reloadRecentlyReturned();
        })
        .catch(reason => {
          this.openErrorMsgBox(reason.message);
        });

      this.removeClass(this.dragData.e.target, this.config.dragHoverClass);
      this.dragData.postData = {};
      this.dragData.e = {};
    },

    /**
     * 区分け表側のカードのドラッグ開始時イベント
     * @param item
     * @param e
     */
    classifyDragStart(item, e) {
      this._dragStart(item, e);
      this.isClassifyDrag = true;
    },
    /**
     * 区分け表側のカードのドラッグ終了時イベント
     * @param item
     * @param e
     */
    classifyDragEnd(item, e) {
      this._dragEnd(item, e);
      this.isClassifyDrag = false;
    },
    /**
     * 区分け表側のカードをドラッグ中、ドロップ要素に入った時のイベント
     * @param item
     * @param e
     */
    classifyDragEnter(item, e) {
      if (this._isClassifiedItem(item)) {
        // e.preventDefault()
        return;
      }
      this._dragEnter(item, e);
    },
    /**
     * 区分け表側のカードをドラッグ中、ドロップ要素から出た時のイベント
     * @param item
     * @param e
     */
    classifyDragLeave(item, e) {
      this._dragLeave(item, e);
    },
    /**
     * 区分け表側のカードをドロップ対象で離した時
     * @param item
     * @param e
     */
    classifyDrop(item, e) {
      if (this.dragElement.select != null) {
        // 自分自身か区分け欄にDropされても何もしない
        if (this._isClassifiedItem(item)) {
          e.preventDefault();
          return;
        }
        this.$store.commit("dispatch/swap", {
          from: this.dragElement.select,
          to: item,
          empty: this.emptyCard,
          opt: { between: false, classifyWrap: false, slideWrap: false }
        });
      }

      this.removeClass(e.target, this.config.dragHoverClass);
    },

    /**
     * スライド表側のカードのドラッグ開始時イベント
     * @param item
     * @param e
     */
    slideClassifyDragStart(item, e) {
      // スライド蘭からスライドした配車日=スライド日のカード対応
      const slideCardId =
        item.firstSlideDate === this.dispatchConditions.scheduledLoadingDate
          ? item.parentCardId
          : item.cardId;
      this.$set(item, "slideCardId", slideCardId);
      this._dragStart(item, e);
    },
    /**
     * スライド表側のカードのドラッグ終了時イベント
     * @param item
     * @param e
     */
    slideClassifyDragEnd(item, e) {
      this._dragEnd(item, e);
    },
    /**
     * スライド表側のカードをドラッグ中、ドロップ要素に入った時のイベント
     * @param item
     * @param e
     */
    slideClassifyDragEnter(item, e) {
      if (this._isSlideItem(item)) {
        e.preventDefault();
        return;
      }
      this._dragEnter(item, e);
    },
    /**
     * スライド表側のカードをドラッグ中、ドロップ要素から出た時のイベント
     * @param item
     * @param e
     */
    slideClassifyDragLeave(item, e) {
      this._dragLeave(item, e);
    },
    /**
     * スライド表側のカードをドロップ対象で離した時
     * @param item
     * @param e
     */
    slideClassifyDrop(item, e) {
      if (this.dragElement.select != null) {
        if (this._isSlideItem(item)) {
          e.preventDefault();
          return;
        }
        this.$store.commit("dispatch/swap", {
          from: this.dragElement.select,
          to: item,
          empty: this.emptyCard,
          opt: { between: false, classifyWrap: false, slideWrap: false }
        });
      }

      this.removeClass(e.target, this.config.dragHoverClass);
    },

    /**
     * 矢印のドラッグ開始時イベント
     * @param item
     * @param e
     */
    betweenDragStart(item, e) {
      e.preventDefault();
    },
    /**
     * 矢印要素に入った時のイベント
     * @param item
     * @param e
     */
    betweenDragEnter(item, e) {
      this._dragEnter(item, e);
    },
    /**
     * 矢印要素から出た時のイベント
     * @param item
     * @param e
     */
    betweenDragLeave(item, e) {
      this._dragLeave(item, e);
    },
    /**
     * 矢印要素で離した時
     * @param item
     * @param e
     */
    async betweenDragDropPre(item, e) {
      if (this.dragElement.select != null) {
        const postData = {
          from: this.dragElement.select,
          to: item,
          empty: this.emptyCard,
          opt: { between: true, classifyWrap: false, slideWrap: false }
        };
        // 事前に確認後処理の引数を退避
        this.dragData.postData = postData;
        this.dragData.e = e;

        await this.$store.dispatch("dispatch/checkDragDrop", postData).then(value => {
          if (value.notEditablePaymentStatus && value.notEditableBillingStatus) {
            this.openErrorMsgBox(this.message.notEditableBillingAndPayment);
            this.removeClass(e.target, this.config.dragHoverClass);
          } else if (value.notEditablePaymentStatus) {
            this.openErrorMsgBox(this.message.notEditablePayment);
            this.removeClass(e.target, this.config.dragHoverClass);
          } else if (value.notEditableBillingStatus) {
            this.openErrorMsgBox(this.message.notEditableBilling);
            this.removeClass(e.target, this.config.dragHoverClass);
          } else if (value.manualBasicRate) {
            this.$refs.DeployWarningDialog.openDialog();
            this.removeClass(e.target, this.config.dragHoverClass);
          } else if (value.paymentStatusTemporaryConfirmed && value.billingStatusTemporaryConfirmed) {
            this.$refs.FinanceOverrideWarningDialogDriver.openDialogWithMessage(
              this.message.temporaryConfirmedBillingAndPayment
            );
            this.removeClass(e.target, this.config.dragHoverClass);
          } else if (value.paymentStatusTemporaryConfirmed) {
            this.$refs.FinanceOverrideWarningDialogDriver.openDialogWithMessage(
              this.message.temporaryConfirmedPayment
            );
            this.removeClass(e.target, this.config.dragHoverClass);
          } else if (value.billingStatusTemporaryConfirmed) {
            this.$refs.FinanceOverrideWarningDialogDriver.openDialogWithMessage(
              this.message.temporaryConfirmedBilling
            );
            this.removeClass(e.target, this.config.dragHoverClass);
          } else {
            this.$refs.FinanceOverrideWarningDialogDriver.yes();
          }
        });
      }
    },
    /**
     * 矢印要素に入った時のイベント
     * @param item
     * @param e
     */
    betweenDragEnterRelay(item, e) {
      if (this._isRelay(this.dragElement.select) || !this._isClassifiedItem(this.dragElement.select)) {
        // 中継カードまたは区分けカード以外はドラッグできない
        e.preventDefault();
        return;
      }
      this._dragEnter(item, e);
    },
    /**
     * 矢印要素(中継)で離した時
     * @param item
     * @param e
     */
    betweenDragDropRelay(item, e) {
      if (
        this.dragElement.select != null &&
        !this._isRelay(this.dragElement.select) &&
        this._isClassifiedItem(this.dragElement.select)
      ) {
        this.$store.commit("dispatch/swap", {
          from: this.dragElement.select,
          to: item,
          empty: this.emptyCard,
          opt: { between: true, classifyWrap: false, slideWrap: false }
        });
      }

      this.removeClass(e.target, this.config.dragHoverClass);
    },

    /**
     * 最近戻したカードのドラッグイベント全般
     * @param item
     * @param e
     */
    historyDragEventCancel(item, e) {
      e.preventDefault();
    },

    _dragStart(item, e) {
      // 空のカードは移動できない
      if (item.data.isBlank === true) {
        e.preventDefault();
        return;
      }
      // this.setClass(this.htmlBody, this.config.dragStartFlagClass);
      this.dragElement.select = item;
      this.setClass(e.target, this.config.dragHoverClass);
      this.setClass(e.target, this.config.dragSelectClass);
    },
    _dragEnd(item, e) {
      // this.removeClass(this.htmlBody, this.config.dragStartFlagClass);
      this.dragElement.select = null;
      this.removeClass(e.target, this.config.dragHoverClass);
      this.removeClass(e.target, this.config.dragSelectClass);
    },
    _dragEnter(item, e) {
      this.setClass(e.target, this.config.dragHoverClass);
    },
    _dragLeave(item, e) {
      this.dragElement.target = null;
      this.removeClass(e.target, this.config.dragHoverClass);
    },
    _isClassifiedItem(item) {
      return item.data.isReserve;
    },
    _isSlideItem(item) {
      return item.data.isSlide;
    },
    _isRelay(item) {
      return item.data.isRelay;
    },
    _isDispatch(item) {
      return item.data.dispatchDetailId !== null;
    },
    _isNightLoading(item) {
      return item.data.isNightLoading;
    },
    _isSelf(item) {
      return (
        item.data.orderId === this.dragElement.select.data.orderId &&
        item.data.isDayExceed === this.dragElement.select.data.isDayExceed &&
        item.data.isRelay === this.dragElement.select.data.isRelay &&
        item.data.isReserve === this.dragElement.select.data.isReserve &&
        item.data.isFirst === this.dragElement.select.data.isFirst
      );
    },
    filterLoadBaseAreaData() {
      this.$refs.ClassifiedFilterCond.loadData(this.classifiedConditions.loadingDate);
    },
    filterReloadBaseAreaData() {
      this.$refs.ClassifiedFilterCond.reloadData(this.classifiedConditions.loadingDate);
    },
    onChangeScheduleConditions(data) {
      this.dispatchConditions = this.$refs.ScheduleFilterBox.addSearchCond(this.dispatchConditions);
      this.clearScheduleAreaScrollTop();
      this.reloadSchedule();
      this.reloadSlide();
      this.reloadRecentlyReturned();

      // フィルタアイコンの色変更処理
      this.isScheduleFilter = false;
      let keys = Object.keys(this.dispatchConditions);
      for (let i = 0, len = keys.length; i < len; i++) {
        if (keys[i] === "scheduledLoadingDate") {
          continue;
        }
        if (Array.isArray(this.dispatchConditions[keys[i]])) {
          if (this.dispatchConditions[keys[i]].length > 0) {
            this.isScheduleFilter = true;
            break;
          }
        } else if (this.dispatchConditions[keys[i]] !== "" && this.dispatchConditions[keys[i]] != null) {
          this.isScheduleFilter = true;
          break;
        }
      }
    },
    clickScheduleConditions() {
      this.reloadDispatchedClient();
      this.reloadGarageArea();
    },
    changeClassifiedConditionsCheck(classifiedConditions) {
      if (
        !this.$refs.ClassifiedFilterCond.isChangedSearchItemArea() &&
        !this.$refs.ClassifiedFilterCond.isChangedSearchItemClient() &&
        _.isEqual(this.classifiedConditions, classifiedConditions)
      ) {
        this.classifiedConditions = classifiedConditions;
        return true;
      } else {
        this.classifiedConditions = classifiedConditions;
        return false;
      }
    },
    onChangeClassifiedConditions() {
      const classifiedConditions = this.$refs.ClassifiedFilterCond.addSearchCond(this.classifiedConditions);
      if (this.changeClassifiedConditionsCheck(classifiedConditions)) {
        this.changeClassifiedFilterIcon();
        return;
      }
      this.changeClassifiedFilterIcon();
      this.reloadReserve();
    },
    changeClassifiedFilterIcon() {
      // フィルタアイコンの色変更処理
      this.isClassifiedFilter = false;
      let keys = Object.keys(this.classifiedConditions);
      for (let i = 0, len = keys.length; i < len; i++) {
        if (keys[i] == "loadingDate") {
          continue;
        }
        if (Array.isArray(this.classifiedConditions[keys[i]])) {
          if (this.classifiedConditions[keys[i]].length > 0) {
            this.isClassifiedFilter = true;
            break;
          }
        } else if (this.classifiedConditions[keys[i]] != "") {
          this.isClassifiedFilter = true;
          break;
        }
      }
    },
    clearScheduleConditions() {
      this.isScheduleFilter = false;
      this.dispatchConditions = this.$refs.ScheduleFilterBox.clearSearchCond(this.dispatchConditions);
      this.reloadSchedule();
    },
    clearClassifiedConditions() {
      this.isClassifiedFilter = false;
      this.classifiedConditions = this.$refs.ClassifiedFilterCond.clearSearchCond(this.classifiedConditions);
      this.reloadReserve();
    },
    triggerOpenPopUpCard(args) {
      this.$refs.ClassifiedCardPopUp.openTrigger(args);
    },
    triggerClosePopUpCard(args) {
      this.$refs.ClassifiedCardPopUp.closeTrigger(args);
    },
    canUseDispatchLineFeature() {
      if (!this.$store.getters["site/canEditFeature"](Enum.FunctionList.DriverSchedule.code)) {
        return false;
      }

      if (!this.$store.getters["site/canReadFeature"](Enum.FunctionList.DeliveryCompany.code)) {
        return false;
      }

      if (!this.$store.getters["site/canReadFeature"](Enum.FunctionList.DriverList.code)) {
        return false;
      }

      if (!this.$store.getters["site/canReadFeature"](Enum.FunctionList.VehicleList.code)) {
        return false;
      }

      return true;
    },
    /**
     * 配車パターンダイアログを開く
     */
    openDispatchPatternDialog() {
      const params = {
        scheduledLoadingDate: this.getScheduledLoadingDate,
        loadingDate: this.classifiedConditions.loadingDate
      };

      this.setDispatchPatternDateInfo(params).then(() => {
        this.$refs.DispatchPatternSelectDialog.openDialog();
      });
    }
  },
  watch: {
    "classifiedConditions.loadingDate": function (value) {
      this.reloadReserve();
    },
    "dispatchConditions.scheduledLoadingDate": function (value) {
      this.$store.commit("dispatch/setScheduledLoadingDate", value);
      this.reloadSchedule();
      this.reloadSlide();
      this.reloadRecentlyReturned();
    },
    isDisplayRelay: function (value) {
      if (value === false) {
        if (this.$store.state.dispatch.relayList.length > 0) {
          for (var i = this.$store.state.dispatch.relayList.length - 1; i >= 0; i--) {
            let _card = this.$store.state.dispatch.relayList[i];
            if (_card.isBlank === false) {
              if (_card._befDriverId) {
                let postData = {
                  from: { itemKey: null, data: _card, driverId: _card._befDriverId },
                  to: { itemKey: null, data: {}, driverId: null },
                  empty: this.emptyCard,
                  opt: { between: false, classifyWrap: true, slideWrap: false }
                };
                this.$store
                  .dispatch("dispatch/dragAndDropRemove", postData)
                  .then(() => {
                    this.$store.state.dispatch.relayList.splice(i, 1);
                  })
                  .catch(reason => {
                    this.openErrorMsgBox(reason.message);
                  });
              } else {
                this.$store.state.dispatch.relayList.splice(i, 1);
              }
            }
          }
        }
      }
      this.reloadReserve();
    },
    scheduleFilterMenu: function (value) {
      if (value === false) {
        this.$refs.ScheduleFilterBox.model = "tab1";
      }
    },
    classifiedFilterMenu: function (value) {
      if (value === false) {
        this.$refs.ClassifiedFilterCond.model = "tab1";
      }
    }
  },
  beforeCreate: function () {
    document.body.className = "dispatch";
  },
  provide() {
    return {
      openDriverDetailDialogInject: this.openDriverDetailDialog,
      openDriverRemarkEditDialogInject: this.openDriverRemarkEditDialog,
      toggleFavoriteInject: this.toggleFavorite,
      openCardDetailDialogInject: this.openCardDetailDialog,
      openMarkMenuInject: this.openMarkMenu,
      openRemarkEditDialogInject: this.openRemarkEditDialog,
      openWildCardEditDialogInject: this.openWildCardEditDialog
    };
  }
};
</script>

<style lang="stylus" scoped>
.dispatch-past-warning {
  margin: 16px 0;
}

body {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}

body::-webkit-scrollbar { /* Chrome, Safari 対応 */
  display: none;
}

.high-density-margin {
  .v-btn {
    margin-left: 0;
    margin-right: 4px;
  }

}
</style>
